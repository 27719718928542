<template>
  <div class="modal__bg" @click="emitClose">
    <div
      class="fondo_modal_agregated"
      @click.stop
      ref="scrollable"
      @scroll="handleScroll"
    >
      <form @submit.prevent="addProductToCart" class="derecha_modal">
        <div
          class="imagen__scroll"
          :class="{
                'class-with-img': !isPlaceholder,
                'class-with-placeholder': isPlaceholder,
                'imagen__scroll-active': validateHeaderScroll()
          }"
          id="header__wrapper"
          :style="{
            backgroundImage: `url(${backgroundPlaceholderItem(payload)})`,
          }"
        >
          <svg
            class="icon-close-scroll"
            @click="emitClose"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z"
              fill="currentColor"
            />
          </svg>
          <div
            class="subtotal"
            :class="{ 'subtotal__scroll-active': validateHeaderScroll() }"
          >
            <h2 class="tp-head-responsive">{{ payload.name }}</h2>
            <p class="tp-title-responsive" v-if="payload.has_discount">
              - S/. {{ parseFloat(payload.promo_price).toFixed(2) }}
            </p>
            <p class="tp-title-responsive" v-if="!payload.has_discount">
              - S/. {{ parseFloat(payload.price).toFixed(2) }}
            </p>
          </div>
        </div>
        <div class="wrapper__information">
          <div class="product_arriba">
            <div class="izquierda_modal">
              <img
                  class="izquierda_modal-img"
                  loading="lazy"
                  :alt=payload.name
                  :srcset="
                    `
                      ${backgroundPlaceholderItem(payload)}?width=200 200w,
                      ${backgroundPlaceholderItem(payload)}?width=400 400w,
                      ${backgroundPlaceholderItem(payload)}?width=600 600w,
                      ${backgroundPlaceholderItem(payload)}?width=800 800w
                    `
                  "
                  :src=backgroundPlaceholderItem(payload)
                  sizes="(max-width: 800px) 100vw, 800px"
              />
            </div>
            <div class="new_product_arriba">
              <div class="new_product_arriba-container">
                <svg
                  class="icon-close"
                  @click="emitClose"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z"
                    fill="currentColor"
                  />
                </svg>
                <div class="titulo_producto">
                  <div class="product_title-price">
                    <h2 class="product_title tp-head-responsive">{{ payload.name }}</h2>
                    <p class="precio_base tp-title-responsive" v-if="payload.has_discount">
                      S/. {{ parseFloat(payload.promo_price).toFixed(2) }}
                    </p>
                    <p class="precio_base tp-title-responsive" v-if="!payload.has_discount">
                      S/. {{ parseFloat(payload.price).toFixed(2) }}
                    </p>
                  </div>
                  <p
                    class="product_description-mobile tp-body-responsive"
                    :class="{
                      'product_description-mobile-active':
                        validateHeaderScroll(),
                    }"
                  >
                    {{ payload.description }}
                  </p>
                  <p class="product_description tp-body-responsive">{{ payload.description }}</p>
                </div>
                <div class="sizes" ref="sizes1Ref">
                  <div class="size" v-show="sizes.length">
                    <div class="titles">
                      <h3 class="titulos_sublistas tp-title-responsive" @click="showSubMenu">
                        {{ payloadOriginal.sizes1_title }}
                        <StatusBadge
                          class="base_component-mobile"
                          :class="{
                            'base_component-mobile-active':
                              first_list_collapsed,
                          }"
                          v-if="payloadOriginal.is_required"
                          :status="statusList(1) ? null : 'danger'"
                        >
                          <span v-html="statusTxtListMobile(1)"></span>
                        </StatusBadge>
                        <svg
                          class="icon_less"
                          :class="{ 'icon_less-active': first_list_collapsed }"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 14.9499C11.8667 14.9499 11.7417 14.9291 11.625 14.8874C11.5084 14.8457 11.4 14.7749 11.3 14.6749L6.70005 10.0749C6.51672 9.89157 6.42505 9.65824 6.42505 9.3749C6.42505 9.09157 6.51672 8.85824 6.70005 8.6749C6.88338 8.49157 7.11672 8.3999 7.40005 8.3999C7.68338 8.3999 7.91672 8.49157 8.10005 8.6749L12 12.5749L15.9 8.6749C16.0834 8.49157 16.3167 8.3999 16.6 8.3999C16.8834 8.3999 17.1167 8.49157 17.3 8.6749C17.4834 8.85824 17.575 9.09157 17.575 9.3749C17.575 9.65824 17.4834 9.89157 17.3 10.0749L12.7 14.6749C12.6 14.7749 12.4917 14.8457 12.375 14.8874C12.2584 14.9291 12.1334 14.9499 12 14.9499Z"
                            fill="currentColor"
                          />
                        </svg>
                      </h3>
                      <StatusBadge
                        v-if="payloadOriginal.is_required"
                        :class="{
                          'base_component-active': first_list_collapsed,
                        }"
                        :status="statusList(1) ? null : 'danger'"
                      >
                        {{ statusTxtList(1) }} ({{
                          payloadOriginal.quantity_product_size
                        }})
                      </StatusBadge>
                      <label
                        class="button-opcional"
                        :class="{
                          'button-opcional-active': first_list_collapsed,
                        }"
                        v-else
                      >
                        {{ $t("modalproductcustomization.txt_opcional") }} ({{
                          payloadOriginal.quantity_product_size
                        }})
                      </label>
                    </div>
                    <div
                      class="size_css size_css-select"
                      :style="getMaxHeight(1)"
                      ref="first_list_height"
                    >
                      <ul
                        class="size_css-varios"
                        v-if="payload.quantity_product_size !== 1"
                        style="width: 100%"
                      >
                        <li
                          class="varios_sizes"
                          v-for="(size, index) in sizes"
                          :key="index"
                          :style="
                            !size.is_available
                              ? '-webkit-filter: grayscale(1); filter: grayscale(1);'
                              : ''
                          "
                          ref="item_varios_sizes"
                          @click="masSize(index, 1, size.is_available)"
                        >
                          <div class="varios_img">
                            <img
                              :src="backgroundPlaceholderItem(size)"
                              alt="imagen"
                            />
                          </div>
                          <p class="tp-body-responsive">{{ size.name }}</p>
                          <div class="secundary-counter opacity">
                            <div>
                              <svg
                                  @click.stop="menosSize(index, 1, size.is_available)"
                                  width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.99984 10.8334C4.76373 10.8334 4.56581 10.7536 4.40609 10.5938C4.24637 10.4341 4.1665 10.2362 4.1665 10.0001C4.1665 9.76397 4.24637 9.56605 4.40609 9.40633C4.56581 9.24661 4.76373 9.16675 4.99984 9.16675H14.9998C15.2359 9.16675 15.4339 9.24661 15.5936 9.40633C15.7533 9.56605 15.8332 9.76397 15.8332 10.0001C15.8332 10.2362 15.7533 10.4341 15.5936 10.5938C15.4339 10.7536 15.2359 10.8334 14.9998 10.8334H4.99984Z" fill="currentColor"/>
                              </svg>
                            </div>
                            <p class="tp-counter-responsive">{{ size.cantidad }}</p>
                            <div>
                              <svg
                                  width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.1665 10.8334H4.99984C4.76373 10.8334 4.56581 10.7536 4.40609 10.5938C4.24637 10.4341 4.1665 10.2362 4.1665 10.0001C4.1665 9.76397 4.24637 9.56605 4.40609 9.40633C4.56581 9.24661 4.76373 9.16675 4.99984 9.16675H9.1665V5.00008C9.1665 4.76397 9.24636 4.56605 9.40609 4.40633C9.56581 4.24661 9.76373 4.16675 9.99984 4.16675C10.2359 4.16675 10.4339 4.24661 10.5936 4.40633C10.7533 4.56605 10.8332 4.76397 10.8332 5.00008V9.16675H14.9998C15.2359 9.16675 15.4339 9.24661 15.5936 9.40633C15.7533 9.56605 15.8332 9.76397 15.8332 10.0001C15.8332 10.2362 15.7533 10.4341 15.5936 10.5938C15.4339 10.7536 15.2359 10.8334 14.9998 10.8334H10.8332V15.0001C10.8332 15.2362 10.7533 15.4341 10.5936 15.5938C10.4339 15.7536 10.2359 15.8334 9.99984 15.8334C9.76373 15.8334 9.56581 15.7536 9.40609 15.5938C9.24636 15.4341 9.1665 15.2362 9.1665 15.0001V10.8334Z" fill="currentColor"/>
                              </svg>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <!--Cuando es solo uno-->
                      <div
                        v-else
                        v-for="(size, index) in sizes"
                        :key="index"
                        class="size_card"
                        :style="
                          !size.is_available
                            ? '-webkit-filter: grayscale(1); filter: grayscale(1);'
                            : ''
                        "
                      >
                        <input
                          type="checkbox"
                          name="size_product"
                          :id="'s1 ' + size.name"
                          :value="size"
                          :disabled="!size.is_available"
                          :checked="sizeSelect.includes(size)"
                          v-model="sizeSelect"
                          @change="modifyExtraSelect('s1 ' + size.name, 1)"
                        />
                        <label class="label_item" :for="'s1' + size.name">
                          <div class="size_image_container">
                            <div class="size_image_container__img">
                              <img
                                :src="backgroundPlaceholderItem(size)"
                                alt="imagen"
                              />
                            </div>
                            <div class="size_image_container-information">
                              <p class="size_image_container-paragraph tp-body-responsive">
                                {{ size.name }}
                              </p>
                              <p
                                class="size_image_container-price"
                                v-if="parseFloat(size.price_additional1) !== 0"
                              >
                                + S/.{{
                                  parseFloat(size.price_additional1).toFixed(2)
                                }}
                              </p>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="size" v-show="sizes2.length" ref="sizes2Ref">
                    <div class="titles">
                      <h3 class="titulos_sublistas tp-title-responsive" @click="showSubMenuDos">
                        {{ payloadOriginal.sizes2_title }}
                        <StatusBadge
                          class="base_component-mobile"
                          :class="{
                            'base_component-mobile-active':
                              second_list_collapsed,
                          }"
                          v-if="payloadOriginal.is_required2"
                          :status="statusList(2) ? null : 'danger'"
                        >
                          <span v-html="statusTxtListMobile(2)"></span>
                        </StatusBadge>
                        <svg
                          class="icon_less"
                          :class="{ 'icon_less-active': second_list_collapsed }"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 14.9499C11.8667 14.9499 11.7417 14.9291 11.625 14.8874C11.5084 14.8457 11.4 14.7749 11.3 14.6749L6.70005 10.0749C6.51672 9.89157 6.42505 9.65824 6.42505 9.3749C6.42505 9.09157 6.51672 8.85824 6.70005 8.6749C6.88338 8.49157 7.11672 8.3999 7.40005 8.3999C7.68338 8.3999 7.91672 8.49157 8.10005 8.6749L12 12.5749L15.9 8.6749C16.0834 8.49157 16.3167 8.3999 16.6 8.3999C16.8834 8.3999 17.1167 8.49157 17.3 8.6749C17.4834 8.85824 17.575 9.09157 17.575 9.3749C17.575 9.65824 17.4834 9.89157 17.3 10.0749L12.7 14.6749C12.6 14.7749 12.4917 14.8457 12.375 14.8874C12.2584 14.9291 12.1334 14.9499 12 14.9499Z"
                            fill="currentColor"
                          />
                        </svg>
                      </h3>

                      <StatusBadge
                        v-if="payloadOriginal.is_required2"
                        :class="{
                          'base_component-active': second_list_collapsed,
                        }"
                        :status="statusList(2) ? null : 'danger'"
                      >
                        {{ statusTxtList(2) }} ({{
                          payloadOriginal.quantity_sizes_2
                        }})
                      </StatusBadge>
                      <label
                        class="button-opcional"
                        :class="{
                          'button-opcional-active': second_list_collapsed,
                        }"
                        v-else
                      >
                        {{ $t("modalproductcustomization.txt_opcional") }} ({{
                          payloadOriginal.quantity_sizes_2
                        }})
                      </label>
                    </div>
                    <ul
                      class="size_css size_css-select"
                      :style="getMaxHeight(2)"
                      ref="second_list_height"
                    >
                      <li
                        v-for="(size2, index2) in sizes2"
                        :key="'s2' + index2"
                        class="size_card"
                        :style="
                          !size2.is_available
                            ? '-webkit-filter: grayscale(1); filter: grayscale(1);'
                            : ''
                        "
                      >
                        <input
                          type="checkbox"
                          name="size2_product"
                          v-model="sizeSelect2"
                          :id="'s2 ' + size2.name"
                          :value="size2"
                          :disabled="!size2.is_available"
                          :checked="sizeSelect2.includes(size2)"
                          @change="modifyExtraSelect('s2 ' + size2.name, 2)"
                        />
                        <label class="label_item" :for="'s2 ' + size2.name">
                          <div class="size_image_container">
                            <div class="size_image_container__img">
                              <img
                                :src="backgroundPlaceholderItem(size2)"
                                alt="imagen"
                              />
                            </div>
                            <div class="size_image_container-information">
                              <p class="size_image_container-paragraph tp-body-responsive">
                                {{ size2.name }}
                              </p>
                              <p
                                class="size_image_container-price"
                                v-if="parseFloat(size2.price_additional2) !== 0"
                              >
                                + S/.{{
                                  parseFloat(size2.price_additional2).toFixed(2)
                                }}
                              </p>
                            </div>
                          </div>
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div class="size" v-show="sizes3.length" ref="sizes3Ref">
                    <div class="titles">
                      <h3 class="titulos_sublistas tp-title-responsive" @click="showSubMenuTres">
                        {{ payloadOriginal.sizes3_title }}
                        <StatusBadge
                          class="base_component-mobile"
                          :class="{
                            'base_component-mobile-active':
                              third_list_collapsed,
                          }"
                          v-if="payloadOriginal.is_required3"
                          :status="statusList(3) ? null : 'danger'"
                        >
                          <span v-html="statusTxtListMobile(3)"></span>
                        </StatusBadge>
                        <svg
                          class="icon_less"
                          :class="{ 'icon_less-active': third_list_collapsed }"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 14.9499C11.8667 14.9499 11.7417 14.9291 11.625 14.8874C11.5084 14.8457 11.4 14.7749 11.3 14.6749L6.70005 10.0749C6.51672 9.89157 6.42505 9.65824 6.42505 9.3749C6.42505 9.09157 6.51672 8.85824 6.70005 8.6749C6.88338 8.49157 7.11672 8.3999 7.40005 8.3999C7.68338 8.3999 7.91672 8.49157 8.10005 8.6749L12 12.5749L15.9 8.6749C16.0834 8.49157 16.3167 8.3999 16.6 8.3999C16.8834 8.3999 17.1167 8.49157 17.3 8.6749C17.4834 8.85824 17.575 9.09157 17.575 9.3749C17.575 9.65824 17.4834 9.89157 17.3 10.0749L12.7 14.6749C12.6 14.7749 12.4917 14.8457 12.375 14.8874C12.2584 14.9291 12.1334 14.9499 12 14.9499Z"
                            fill="currentColor"
                          />
                        </svg>
                      </h3>
                      <StatusBadge
                        v-if="payloadOriginal.is_required3"
                        :class="{
                          'base_component-active': third_list_collapsed,
                        }"
                        :status="statusList(3) ? null : 'danger'"
                      >
                        {{ statusTxtList(3) }} ({{
                          payloadOriginal.quantity_sizes_3
                        }})
                      </StatusBadge>
                      <label
                        class="button-opcional"
                        :class="{
                          'button-opcional-active': third_list_collapsed,
                        }"
                        v-else
                      >
                        {{ $t("modalproductcustomization.txt_opcional") }} ({{
                          payloadOriginal.quantity_sizes_3
                        }})
                      </label>
                    </div>
                    <ul
                      class="size_css size_css-select"
                      :style="getMaxHeight(3)"
                      ref="third_list_height"
                    >
                      <li
                        v-for="(size3, index3) in sizes3"
                        :key="index3"
                        class="size_card"
                        :style="
                          !size3.is_available
                            ? '-webkit-filter: grayscale(1); filter: grayscale(1);'
                            : ''
                        "
                      >
                        <input
                          type="checkbox"
                          name="size3_product"
                          :id="'s3 ' + size3.name"
                          :value="size3"
                          v-model="sizeSelect3"
                          :checked="sizeSelect3.includes(size3)"
                          :disabled="!size3.is_available"
                          @change="modifyExtraSelect('s3 ' + size3.name, 3)"
                        />
                        <label class="label_item" :for="'s3 ' + size3.name">
                          <div class="size_image_container">
                            <div class="size_image_container__img">
                              <img
                                :src="backgroundPlaceholderItem(size3)"
                                alt="imagen"
                              />
                            </div>
                            <div class="size_image_container-information">
                              <p class="size_image_container-paragraph tp-body-responsive">
                                {{ size3.name }}
                              </p>
                              <p
                                class="size_image_container-price"
                                v-if="parseFloat(size3.price_additional3) !== 0"
                              >
                                + S/.{{
                                  parseFloat(size3.price_additional3).toFixed(2)
                                }}
                              </p>
                            </div>
                          </div>
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div class="size" v-show="sizes4.length" ref="sizes4Ref">
                    <div class="titles">
                      <h3
                        class="titulos_sublistas tp-title-responsive"
                        @click="showSubMenuCuatro"
                        v-show="sizes4.length"
                      >
                        {{ payloadOriginal.sizes4_title }}
                        <StatusBadge
                          class="base_component-mobile"
                          :class="{
                            'base_component-mobile-active':
                              fourth_list_collapsed,
                          }"
                          v-if="payloadOriginal.is_required4"
                          :status="statusList(4) ? null : 'danger'"
                        >
                          <span v-html="statusTxtListMobile(4)"></span>
                        </StatusBadge>
                        <svg
                          class="icon_less"
                          :class="{ 'icon_less-active': fourth_list_collapsed }"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 14.9499C11.8667 14.9499 11.7417 14.9291 11.625 14.8874C11.5084 14.8457 11.4 14.7749 11.3 14.6749L6.70005 10.0749C6.51672 9.89157 6.42505 9.65824 6.42505 9.3749C6.42505 9.09157 6.51672 8.85824 6.70005 8.6749C6.88338 8.49157 7.11672 8.3999 7.40005 8.3999C7.68338 8.3999 7.91672 8.49157 8.10005 8.6749L12 12.5749L15.9 8.6749C16.0834 8.49157 16.3167 8.3999 16.6 8.3999C16.8834 8.3999 17.1167 8.49157 17.3 8.6749C17.4834 8.85824 17.575 9.09157 17.575 9.3749C17.575 9.65824 17.4834 9.89157 17.3 10.0749L12.7 14.6749C12.6 14.7749 12.4917 14.8457 12.375 14.8874C12.2584 14.9291 12.1334 14.9499 12 14.9499Z"
                            fill="currentColor"
                          />
                        </svg>
                      </h3>
                      <StatusBadge
                        v-if="payloadOriginal.is_required4"
                        :class="{
                          'base_component-active': fourth_list_collapsed,
                        }"
                        :status="statusList(4) ? null : 'danger'"
                      >
                        {{ statusTxtList(4) }} ({{
                          payloadOriginal.quantity_sizes_4
                        }})
                      </StatusBadge>
                      <label
                        class="button-opcional"
                        :class="{
                          'button-opcional-active': fourth_list_collapsed,
                        }"
                        v-else
                      >
                        {{ $t("modalproductcustomization.txt_opcional") }} ({{
                          payloadOriginal.quantity_sizes_4
                        }})
                      </label>
                    </div>
                    <ul
                      class="size_css size_css-select"
                      :style="getMaxHeight(4)"
                      ref="fourth_list_height"
                    >
                      <li
                        v-for="(size4, index4) in sizes4"
                        :key="index4"
                        class="size_card"
                        :style="
                          !size4.is_available
                            ? '-webkit-filter: grayscale(1); filter: grayscale(1);'
                            : ''
                        "
                      >
                        <input
                          type="checkbox"
                          name="size4_product"
                          :id="'s4 ' + size4.name"
                          :value="size4"
                          :checked="sizeSelect4.includes(size4)"
                          v-model="sizeSelect4"
                          :disabled="!size4.is_available"
                          @change="modifyExtraSelect('s4 ' + size4.name, 4)"
                        />
                        <label class="label_item" :for="'s4 ' + size4.name">
                          <div class="size_image_container">
                            <div class="size_image_container__img">
                              <img
                                :src="backgroundPlaceholderItem(size4)"
                                alt="imagen"
                              />
                            </div>
                            <div class="size_image_container-information">
                              <p class="size_image_container-paragraph tp-body-responsive">
                                {{ size4.name }}
                              </p>
                              <p
                                class="size_image_container-price"
                                v-if="parseFloat(size4.price_additional4) !== 0"
                              >
                                + S/.{{
                                  parseFloat(size4.price_additional4).toFixed(2)
                                }}
                              </p>
                            </div>
                          </div>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="extra"
                  v-show="
                    payloadOriginal.is_available_additional &&
                    additionals.length > 0
                  "
                >
                  <div class="additionals">
                    <div class="titles">
                      <h3 class="titulos_sublistas tp-title-responsive" @click="showSubMenuCinco">
                        {{
                          $t(
                            "modalproductcustomization.txt_deseas_agregar_algo_mas"
                          )
                        }}
                        <svg
                          class="icon_less"
                          :class="{ 'icon_less-active': five_list_collapsed }"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 14.9499C11.8667 14.9499 11.7417 14.9291 11.625 14.8874C11.5084 14.8457 11.4 14.7749 11.3 14.6749L6.70005 10.0749C6.51672 9.89157 6.42505 9.65824 6.42505 9.3749C6.42505 9.09157 6.51672 8.85824 6.70005 8.6749C6.88338 8.49157 7.11672 8.3999 7.40005 8.3999C7.68338 8.3999 7.91672 8.49157 8.10005 8.6749L12 12.5749L15.9 8.6749C16.0834 8.49157 16.3167 8.3999 16.6 8.3999C16.8834 8.3999 17.1167 8.49157 17.3 8.6749C17.4834 8.85824 17.575 9.09157 17.575 9.3749C17.575 9.65824 17.4834 9.89157 17.3 10.0749L12.7 14.6749C12.6 14.7749 12.4917 14.8457 12.375 14.8874C12.2584 14.9291 12.1334 14.9499 12 14.9499Z"
                            fill="currentColor"
                          />
                        </svg>
                      </h3>
                    </div>
                    <div>
                      <ul
                        class="size_css-select"
                        :style="getMaxHeight(5)"
                        ref="five_list_height"
                      >
                        <li
                          class="varios_sizes"
                          v-for="(item, index) in additionals"
                          :key="index"
                          :style="
                            !item.is_available
                              ? '-webkit-filter: grayscale(1); filter: grayscale(1);'
                              : ''
                          "
                          @click="masSize(index, 6, item.is_available)"
                        >
                          <div class="varios_img">
                            <img
                              :src="backgroundPlaceholderItem(item)"
                              alt="imagen"
                            />
                          </div>
                          <div class="varios__information">
                            <p class="tp-body-responsive">{{ item.name }}</p>
                            <p class="tp-body-responsive" v-if="item.cantidad === 0">
                              + S/.{{ parseFloat(item.price).toFixed(2) }}
                            </p>
                            <p class="tp-body-responsive" v-if="item.cantidad !== 0">
                              + S/.{{
                                parseFloat(
                                  parseFloat(item.price).toFixed(2) *
                                    item.cantidad
                                ).toFixed(2)
                              }}
                            </p>
                          </div>
                          <div class="secundary-counter">
                            <div v-if="item.cantidad > 0">
                              <svg
                                  @click.stop="menosSize(index, 6, item.is_available)"
                                  width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.99984 10.8334C4.76373 10.8334 4.56581 10.7536 4.40609 10.5938C4.24637 10.4341 4.1665 10.2362 4.1665 10.0001C4.1665 9.76397 4.24637 9.56605 4.40609 9.40633C4.56581 9.24661 4.76373 9.16675 4.99984 9.16675H14.9998C15.2359 9.16675 15.4339 9.24661 15.5936 9.40633C15.7533 9.56605 15.8332 9.76397 15.8332 10.0001C15.8332 10.2362 15.7533 10.4341 15.5936 10.5938C15.4339 10.7536 15.2359 10.8334 14.9998 10.8334H4.99984Z" fill="currentColor"/>
                              </svg>
                            </div>
                            <p v-if="item.cantidad > 0" class="tp-counter-responsive">{{ item.cantidad }}</p>
                            <div :class="item.cantidad > 0 ? '' : 'counter-svg-size'">
                              <svg
                                  width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.1665 10.8334H4.99984C4.76373 10.8334 4.56581 10.7536 4.40609 10.5938C4.24637 10.4341 4.1665 10.2362 4.1665 10.0001C4.1665 9.76397 4.24637 9.56605 4.40609 9.40633C4.56581 9.24661 4.76373 9.16675 4.99984 9.16675H9.1665V5.00008C9.1665 4.76397 9.24636 4.56605 9.40609 4.40633C9.56581 4.24661 9.76373 4.16675 9.99984 4.16675C10.2359 4.16675 10.4339 4.24661 10.5936 4.40633C10.7533 4.56605 10.8332 4.76397 10.8332 5.00008V9.16675H14.9998C15.2359 9.16675 15.4339 9.24661 15.5936 9.40633C15.7533 9.56605 15.8332 9.76397 15.8332 10.0001C15.8332 10.2362 15.7533 10.4341 15.5936 10.5938C15.4339 10.7536 15.2359 10.8334 14.9998 10.8334H10.8332V15.0001C10.8332 15.2362 10.7533 15.4341 10.5936 15.5938C10.4339 15.7536 10.2359 15.8334 9.99984 15.8334C9.76373 15.8334 9.56581 15.7536 9.40609 15.5938C9.24636 15.4341 9.1665 15.2362 9.1665 15.0001V10.8334Z" fill="currentColor"/>
                              </svg>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="wrapper__precio-2">
                <div class="message-price" v-if="showWarningExtraItems">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4376_3572)">
                      <path
                        d="M12 22C13.1 22 14 21.1 14 20H10C10 21.1 10.9 22 12 22ZM18 16V11C18 7.93 16.37 5.36 13.5 4.68V4C13.5 3.17 12.83 2.5 12 2.5C11.17 2.5 10.5 3.17 10.5 4V4.68C7.64 5.36 6 7.92 6 11V16L4 18V19H20V18L18 16ZM16 17H8V11C8 8.52 9.51 6.5 12 6.5C14.49 6.5 16 8.52 16 11V17Z"
                        fill="currentColor"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4376_3572">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span>Se multiplicará por {{payload.SelectCantidad}} todas las opciones que elija, incluyendo adicionales.</span>
                  <svg
                    @click="showWarningExtraItems = false"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4376_3574)">
                      <path
                        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                        fill="currentColor"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4376_3574">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div class="precio_desktop-2">
                  <div class="precio_izquierda">
                    <div class="precio_izquierda-p-span">
                      <p class="tp-body-responsive">
                        {{ $t("modalproductcustomization.txt_precio_base") }}
                      </p>
                      <span class="tp-body-responsive" v-if="payload.has_discount"
                        >S/{{
                          parseFloat(payload.promo_price).toFixed(2)
                        }}</span
                      >
                      <span class="tp-body-responsive" v-if="!payload.has_discount"
                        >S/{{ parseFloat(payload.price).toFixed(2) }}</span
                      >
                      <p class="tp-body-responsive" v-if="additionals_of_size() > 0">
                        {{ $t("modalmicarrito.txt_extras") }}
                      </p>
                      <span class="tp-body-responsive" v-if="additionals_of_size() > 0"
                        >S/{{ additionals_of_size().toFixed(2) }}</span
                      >
                      <p class="tp-body-responsive" v-if="additionals_of_size() > 0">
                        {{ $t("modalmicarrito.txt_total_double_dot") }}
                      </p>
                      <span class="tp-body-responsive" v-if="additionals_of_size() > 0"
                        >S/{{
                          (subTotal * payload.SelectCantidad).toFixed(2)
                        }}</span
                      >
                    </div>
                  </div>
                  <div class="precio_derecha">
                    <div class="component_agregar">
                      <span class="componentCantidad">
                        <ComponentCantidad
                            :quantity="payload.SelectCantidad"
                            :loading="false"
                            @menos="payload.SelectCantidad -= 1;"
                            @mas="payload.SelectCantidad += 1; showWarningExtraItems = true;"
                        ></ComponentCantidad>
                      </span>
                      <ButtonPrimary class="btn_add_product" type="submit">
                        {{ operationName() }} (S/{{
                          (subTotal * payload.SelectCantidad).toFixed(2)
                        }})
                      </ButtonPrimary>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="wrapper__precio">
          <div class="message-price" v-if="showWarningExtraItems">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_4376_3572)">
                <path
                  d="M12 22C13.1 22 14 21.1 14 20H10C10 21.1 10.9 22 12 22ZM18 16V11C18 7.93 16.37 5.36 13.5 4.68V4C13.5 3.17 12.83 2.5 12 2.5C11.17 2.5 10.5 3.17 10.5 4V4.68C7.64 5.36 6 7.92 6 11V16L4 18V19H20V18L18 16ZM16 17H8V11C8 8.52 9.51 6.5 12 6.5C14.49 6.5 16 8.52 16 11V17Z"
                  fill="currentColor"
                />
              </g>
              <defs>
                <clipPath id="clip0_4376_3572">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span class="tp-body-responsive">Se multiplicará por {{payload.SelectCantidad}} todas las opciones que elija, incluyendo adicionales.</span>
            <svg
              @click="showWarningExtraItems = false"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_4376_3574)">
                <path
                  d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                  fill="currentColor"
                />
              </g>
              <defs>
                <clipPath id="clip0_4376_3574">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="precio_desktop">
            <div class="precio_izquierda">
              <div class="precio_izquierda-p-span">
                <p class="tp-body-responsive">{{ $t("modalproductcustomization.txt_precio_base") }}</p>
                <span class="tp-body-responsive" v-if="payload.has_discount"
                  >S/{{ parseFloat(payload.promo_price).toFixed(2) }}</span
                >
                <span class="tp-body-responsive" v-if="!payload.has_discount"
                  >S/{{ parseFloat(payload.price).toFixed(2) }}</span
                >

                <p class="tp-body-responsive" v-if="additionals_of_size() > 0">
                  {{ $t("modalmicarrito.txt_extras") }}
                </p>
                <span class="tp-body-responsive" v-if="additionals_of_size() > 0"
                  >S/{{ additionals_of_size().toFixed(2) }}</span
                >
                <p class="tp-body-responsive" v-if="additionals_of_size() > 0">
                  {{ $t("modalmicarrito.txt_total_double_dot") }}
                </p>
                <span class="tp-body-responsive" v-if="additionals_of_size() > 0"
                  >S/{{ (subTotal * payload.SelectCantidad).toFixed(2) }}</span
                >
              </div>
            </div>
            <div class="precio_derecha">
              <div class="component_agregar">
                <span class="componentCantidad">
                  <ComponentCantidad
                      :quantity="payload.SelectCantidad"
                      :loading="false"
                      @menos="payload.SelectCantidad -= 1;"
                      @mas="payload.SelectCantidad += 1; showWarningExtraItems = true;"
                  ></ComponentCantidad>
                </span>
                <ButtonPrimary class="btn_add_product" type="submit">
                  {{ operationName() }} (S/{{
                    (subTotal * payload.SelectCantidad).toFixed(2)
                  }})
                </ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import LocalServices from "@/services/local-services";
import OrderServices from "@/services/order-services";
import ComponentCantidad from "../PrimaryCounter";
import { mapState, mapMutations } from "vuex";
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary.vue";
import { cloneDeep } from "lodash";
import StatusBadge from "@/components/new/buttons/StatusBadge";
export default {
  name: "ModalProductCustomization",
  props: ["list_additional_items", "productId", "selectedItemCarrito"],
  emits: ["close", "pay"],
  components: {
    StatusBadge,
    ButtonPrimary,
    ComponentCantidad,
  },
  data() {
    return {
      currentClass: '',
      showWarningExtraItems: false,
      scrollPosition: 0,
      payload: {
        sizes: [],
        sizes2: [],
        sizes3: [],
        sizes4: [],
        SelectSize: [],
        SelectCantidad: 1,
      },
      payloadOriginal: {},
      sizes: [],
      sizes2: [],
      sizes3: [],
      sizes4: [],
      additionals: [],
      sizeSelect: [],
      sizeSelect2: [],
      sizeSelect3: [],
      sizeSelect4: [],
      subTotal: 0,
      first_list_collapsed: true,
      second_list_collapsed: true,
      third_list_collapsed: true,
      fourth_list_collapsed: true,
      five_list_collapsed: true,
      firstListHeight: null,
      secondListHeight: null,
      thirdlListHeight: null,
      fourthListHeight: null,
      fiveListHeight: null,
      first_load: true,
      modal_closed: false,
    };
  },
  async mounted() {
    try {
      await this.loadAndFindProduct();
      await this.loadAdditionalItems();
      this.updateCurrentSelection();
    } catch (error) {
      console.log(error);
    }
    //seleccion del primer size por defecto
    if (this.payload.sizes.length === 1 && this.selectedItemCarrito === null) {
      if (
        document.getElementById("s1 " + this.payload.sizes[0].name) !== null
      ) {
        this.sizeSelect.push(this.payload.sizes[0]);
        this.payload.sizes[0].cantidad = 1;
      }
    }
    await this.$nextTick();
    setTimeout(() => {
      if (!this.modal_closed) {
        this.firstListHeight = this.$refs.first_list_height.offsetHeight;
        this.secondListHeight = this.$refs.second_list_height.offsetHeight;
        this.thirdlListHeight = this.$refs.third_list_height.offsetHeight;
        this.fourthListHeight = this.$refs.fourth_list_height.offsetHeight;
        this.fiveListHeight = this.$refs.five_list_height.offsetHeight;
        this.first_load = false;
      }
    }, 1000);
  },
  computed: {
    isPlaceholder() {
      const placeholderUrl = process.env.VUE_APP_IMG_PLACEHOLDER;
      return this.backgroundPlaceholderItem(this.payload) === placeholderUrl;
    },
    ...mapState(["isAuthenticated", "typeOrder", "orderLocal"]),
  },

  methods: {
    getMaxHeight(list) {
      switch (list) {
        case 1:
          if (this.first_load) {
            return `max-height: 30000px`;
          }
          if (this.first_list_collapsed) {
            return `max-height: ${this.firstListHeight}px`;
          }
          return `max-height: 0`;
        case 2:
          if (this.first_load) {
            return `max-height: 30000px`;
          }
          if (this.second_list_collapsed) {
            return `max-height: ${this.secondListHeight}px`;
          }
          return `max-height: 0`;
        case 3:
          if (this.first_load) {
            return `max-height: 30000px`;
          }
          if (this.third_list_collapsed) {
            return `max-height: ${this.thirdlListHeight}px`;
          }
          return `max-height: 0`;
        case 4:
          if (this.first_load) {
            return `max-height: 30000px`;
          }
          if (this.fourth_list_collapsed) {
            return `max-height: ${this.fourthListHeight}px`;
          }
          return `max-height: 0`;
        case 5:
          if (this.first_load) {
            return `max-height: 30000px`;
          }
          if (this.five_list_collapsed) {
            return `max-height: ${this.fiveListHeight}px`;
          }
          return `max-height: 0`;
      }
    },
    handleScroll(event) {
      this.scrollPosition = event.target.scrollTop;
    },
    validateHeaderScroll() {
      if (this.first_load) {
        return false;
      }
      let element = document.getElementById("header__wrapper");
      if (element.classList.contains("imagen__scroll-active")) {
        return this.scrollPosition !== 0;
      }
      return this.scrollPosition > 71; // diferencia entre altura sin active y con active: 150 - 80 = 70px
    },
    statusList(number) {
      let quantity_selected = 0;
      switch (number) {
        case 1:
          this.sizes.forEach((item) => {
            quantity_selected += item.cantidad;
          });
          return (
            quantity_selected ===
            parseInt(this.payloadOriginal.quantity_product_size)
          );
        case 2:
          return (
            this.sizeSelect2.length ===
            parseInt(this.payloadOriginal.quantity_sizes_2)
          );
        case 3:
          return (
            this.sizeSelect3.length ===
            parseInt(this.payloadOriginal.quantity_sizes_3)
          );
        case 4:
          return (
            this.sizeSelect4.length ===
            parseInt(this.payloadOriginal.quantity_sizes_4)
          );
      }
      return false;
    },

    statusTxtList(number) {
      if (this.statusList(number)) {
        return "Completado";
      }
      return "Requerido";
    },

    statusTxtListMobile(string) {
      if (this.statusList(string)) {
        return`
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.54999 15.15L18.025 6.675C18.225 6.475 18.4625 6.375 18.7375 6.375C19.0125 6.375 19.25 6.475 19.45 6.675C19.65 6.875 19.75 7.1125 19.75 7.3875C19.75 7.6625 19.65 7.9 19.45 8.1L10.25 17.3C10.05 17.5 9.81666 17.6 9.54999 17.6C9.28333 17.6 9.04999 17.5 8.84999 17.3L4.54999 13C4.34999 12.8 4.25416 12.5625 4.26249 12.2875C4.27083 12.0125 4.37499 11.775 4.57499 11.575C4.77499 11.375 5.01249 11.275 5.28749 11.275C5.56249 11.275 5.79999 11.375 5.99999 11.575L9.54999 15.15Z" fill="currentColor"/>
          </svg>
        `;
      }
      return`
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z" fill="currentColor"/>
            <path d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z" fill="currentColor" fill-opacity="0.2"/>
            <path d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z" fill="currentColor" fill-opacity="0.2"/>
            <path d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z" fill="currentColor" fill-opacity="0.2"/>
          </svg>
        `;
    },

    handleInvalid(refObject) {
      refObject.classList.add("error__animation");

      setTimeout(() => {
        refObject.classList.remove("error__animation");
      }, 500);
    },
    operationName() {
      if (this.selectedItemCarrito === null) {
        return this.$t("buttons.txt_agregar");
      }
      return this.$t("buttons.txt_confirmar");
    },
    updateCurrentSelection() {
      if (this.selectedItemCarrito === null) {
        return;
      }
      if (this.payload.quantity_product_size > 1) {
        this.selectedItemCarrito.product_size_ids.forEach((sizeId) => {
          const size = this.sizes.find((s) => s.id === sizeId);

          if (size) {
            size.cantidad += 1;
          }
        });
      } else {
        this.sizeSelect = this.sizes.filter((size) =>
          this.selectedItemCarrito.product_size_ids.includes(size.id)
        );
        this.sizes.forEach((item) => {
          const selected = this.sizeSelect.find((s) => s.id === item.id);
          if (selected) {
            item.cantidad = 1;
          } else {
            item.cantidad = 0;
          }
        });
      }
      this.sizeSelect2 = this.sizes2.filter((size) =>
        this.selectedItemCarrito.product_sizes2_ids.includes(size.id)
      );
      this.sizeSelect3 = this.sizes3.filter((size) =>
        this.selectedItemCarrito.product_sizes3_ids.includes(size.id)
      );
      this.sizeSelect4 = this.sizes4.filter((size) =>
        this.selectedItemCarrito.product_sizes4_ids.includes(size.id)
      );

      this.selectedItemCarrito.ingredients_add.forEach((ingredientId) => {
        let additional = this.additionals.find(
          (additional) => additional.id === ingredientId
        );

        if (additional) {
          additional.cantidad += 1;
        }
      });
      this.payload.SelectCantidad = this.selectedItemCarrito.cant;

      this.$forceUpdate();
      this.modifyExtraSelect();
    },
    emitClose() {
      this.modal_closed = true;
      this.$emit("close");
    },
    proceedToPay() {
      this.modal_closed = true;
      this.$emit("pay");
    },
    backgroundPlaceholderItem(product) {
      try {
        new URL(product.img);
        return product.img;
      } catch (error) {
        return process.env.VUE_APP_IMG_PLACEHOLDER;
      }
    },
    additionals_of_size() {
      let aux = 0;
      this.sizes.map((size) => {
        aux = aux + parseFloat(size.price_additional1) * size.cantidad;
      });
      //costos de sizes2
      this.sizeSelect2.map((size2) => {
        aux = aux + parseFloat(size2.price_additional2);
      });
      //costos de sizes3
      this.sizeSelect3.map((size3) => {
        aux = aux + parseFloat(size3.price_additional3);
      });
      //costos de sizes4
      this.sizeSelect4.map((size4) => {
        aux = aux + parseFloat(size4.price_additional4);
      });

      this.additionals.map((item) => {
        aux = aux + parseFloat(item.price_additional) * item.cantidad;
      });
      return aux;
    },
    cantidadSelecionadaSizes: function () {
      let aux = 0;
      for (let i = 0; i < this.sizes.length; i++) {
        aux = aux + this.sizes[i].cantidad;
      }
      return aux;
    },
    menosSize(index, listNumber, is_available) {
      const extrasItemRemove = document.querySelector(
        `.varios_sizes:nth-child(${index + 1})`
      );
      const contadores = document.querySelectorAll('.secundary-counter');
      if (!is_available) {
        this.$toast.error(this.$t("toast.txt_presentacion_no_disponible"));
        return;
      }
      switch (listNumber) {
        case 1:
          if (this.sizes[index].cantidad > 0) {
            this.sizes[index].cantidad = this.sizes[index].cantidad - 1;
          }
          break;
        case 6:
          if (this.additionals[index].cantidad === 0) {
            return;
          }
          this.additionals[index].cantidad =
            this.additionals[index].cantidad - 1;
          break;
      }
      if (listNumber === 1 && this.sizes[index].cantidad === 0) {
        extrasItemRemove.style.backgroundColor = "#ffcfbd";
        contadores[index].classList.add('opacity');
      } else if (listNumber === 6 && this.additionals[index].cantidad === 0) {
        extrasItemRemove.style.backgroundColor = "#ffcfbd";
        contadores[index].classList.add('opacity');
      }
      this.$forceUpdate();
      this.modifyExtraSelect();
    },
    masSize(index, listNumber, is_available) {
      const extrasItemAdd = document.querySelector(`.varios_sizes:nth-child(${index + 1})`);
      const contadores = document.querySelectorAll('.secundary-counter');
      if (!is_available) {
        this.$toast.error(this.$t("toast.txt_presentacion_no_disponible"));
        return;
      }

      switch (listNumber) {
        case 1:
          if (
            this.cantidadSelecionadaSizes() <
            parseInt(this.payloadOriginal.quantity_product_size)
          ) {
            this.sizes[index].cantidad = this.sizes[index].cantidad + 1;
            extrasItemAdd.style.backgroundColor = "#ffcfbd";
            contadores[index].classList.remove('opacity');
          } else {
            this.$toast.info(
              this.$t("toast.txt_maximo_quantity_productos", {
                quantity: this.payloadOriginal.quantity_product_size,
              })
            );
          }
          break;
        case 6:
          this.additionals[index].cantidad =
            this.additionals[index].cantidad + 1;
          extrasItemAdd.style.backgroundColor = "#ffcfbd";
          contadores[index].classList.remove('opacity');
      }
      this.$forceUpdate();
      this.modifyExtraSelect();
      this.showWarningExtraItems = this.payload.SelectCantidad > 1;
    },
    async loadAndFindProduct() {
      let response;
      if (this.typeOrder !== undefined && this.typeOrder != null) {
        response = await LocalServices.singleProductFullInfo(
          this.typeOrder,
          this.productId
        );
      } else {
        response = await LocalServices.singleProductFullInfo(3, this.productId);
      }
      if (response.data.success) {
        this.payloadOriginal = response.data.data;
        if (response.data.data.sizes.length === 0) {
          this.$toast.error(
            this.$t("toast.txt_el_producto_no_se_encuentra_disponible")
          );
          this.emitClose();
          return;
        }
      } else {
        this.$toast.error(this.$t("toast.txt_hubo_un_error_al_cargar_la"));
        console.log("error modal prodcustom", response.data.message);
        this.emitClose();
        return;
      }
      this.payloadOriginal.sizes = this.payloadOriginal.sizes.filter((item) => {
        if (!item.deleted) {
          return item;
        }
      });
      if (this.payloadOriginal.sizes.length === 0) {
        this.$toast.error(this.$t("toast.txt_el_producto_esta_agotado"));
        this.emitClose();
        return;
      }
      this.payloadOriginal.sizes2 = this.payloadOriginal.sizes2.filter(
        (item) => {
          if (!item.deleted) {
            return item;
          }
        }
      );
      this.payloadOriginal.sizes3 = this.payloadOriginal.sizes3.filter(
        (item) => {
          if (!item.deleted) {
            return item;
          }
        }
      );
      this.payloadOriginal.sizes4 = this.payloadOriginal.sizes4.filter(
        (item) => {
          if (!item.deleted) {
            return item;
          }
        }
      );
      this.payload = Object.assign(
        {},
        {
          ...this.payloadOriginal,
          SelectSize: [],
          SelectCantidad: 1,
        }
      );
      this.sizes = [...this.payloadOriginal.sizes];
      this.sizes2 = [...this.payloadOriginal.sizes2];
      this.sizes3 = [...this.payloadOriginal.sizes3];
      this.sizes4 = [...this.payloadOriginal.sizes4];
      this.sizes.forEach((size) => {
        size.cantidad = 0;
      });
      if (this.payload.has_discount) {
        this.subTotal = parseFloat(this.payload.promo_price);
      } else {
        this.subTotal = parseFloat(this.payload.price);
      }
    },
    async loadAdditionalItems() {
      if (this.list_additional_items !== null) {
        this.additionals = cloneDeep(this.list_additional_items);
      }
    },
    ...mapMutations(["setShowModalLoginRegister", "pushShoppingCartProduct"]),
    setSizesSelect() {
      this.sizeSelect = [];
      this.sizes.map((size) => {
        for (let i = 0; i < size.cantidad; i++) {
          this.sizeSelect.push(size);
        }
      });
    },
    async addProductToCart() {
      this.setSizesSelect();
      if (this.sizeSelect.length === 0) {
        if (
          this.payloadOriginal.is_required ||
          this.payloadOriginal.quantity_product_size === 1
        ) {
          this.$toast.error(
            this.$t("toast.txt_debes_seleccionar_quantity_productos", {
              quantity: this.payloadOriginal.quantity_product_size,
              title: this.payloadOriginal.sizes1_title,
            })
          );
          this.$refs.sizes1Ref.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
          this.handleInvalid(this.$refs.sizes1Ref);
          return;
        }
        this.$toast.error(
          this.$t("toast.txt_debes_seleccionar_entre_1_y_quantity", {
            quantity: this.payloadOriginal.quantity_product_size,
            title: this.payloadOriginal.sizes1_title,
          })
        );
        this.$refs.sizes1Ref.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        this.handleInvalid(this.$refs.sizes1Ref);
        return;
      }
      if (this.sizes.length >= 1) {
        if (this.payloadOriginal.is_required) {
          if (
            this.sizeSelect.length !==
            parseInt(this.payloadOriginal.quantity_product_size)
          ) {
            this.$toast.error(
              this.$t("toast.txt_debes_seleccionar_quantity_productos", {
                quantity: this.payloadOriginal.quantity_product_size,
                title: this.payloadOriginal.sizes1_title,
              })
            );
            this.$refs.sizes1Ref.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
            this.handleInvalid(this.$refs.sizes1Ref);
            return;
          }
        }
        if (
          this.sizeSelect.length < 1 &&
          this.sizeSelect.length >
            parseInt(this.payloadOriginal.quantity_product_size)
        ) {
          this.$toast.error(
            this.$t("toast.txt_debes_seleccionar_entre_1_y_quantity", {
              quantity: this.payloadOriginal.quantity_product_size,
              title: this.payloadOriginal.sizes1_title,
            })
          );
          this.$refs.sizes1Ref.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
          this.handleInvalid(this.$refs.sizes1Ref);
          return;
        }
        if (
          this.payloadOriginal.is_required2 &&
          this.sizeSelect2.length !==
            parseInt(this.payloadOriginal.quantity_sizes_2)
        ) {
          this.$toast.error(
            this.$t("toast.txt_debes_seleccionar_quantity_productos", {
              quantity: this.payloadOriginal.quantity_sizes_2,
              title: this.payloadOriginal.sizes2_title,
            })
          );
          this.$refs.sizes2Ref.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
          this.handleInvalid(this.$refs.sizes2Ref);
          return;
        }
        if (
          this.payloadOriginal.is_required3 &&
          this.sizeSelect3.length !==
            parseInt(this.payloadOriginal.quantity_sizes_3)
        ) {
          this.$toast.error(
            this.$t("toast.txt_debes_seleccionar_quantity_productos", {
              quantity: this.payloadOriginal.quantity_sizes_3,
              title: this.payloadOriginal.sizes3_title,
            })
          );
          this.$refs.sizes3Ref.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
          this.handleInvalid(this.$refs.sizes3Ref);
          return;
        }
        if (
          this.payloadOriginal.is_required4 &&
          this.sizeSelect4.length !==
            parseInt(this.payloadOriginal.quantity_sizes_4)
        ) {
          this.$toast.error(
            this.$t("toast.txt_debes_seleccionar_quantity_productos", {
              quantity: this.payloadOriginal.quantity_sizes_4,
              title: this.payloadOriginal.sizes4_title,
            })
          );
          this.$refs.sizes4Ref.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
          this.handleInvalid(this.$refs.sizes4Ref);
          return;
        }
      }
      if (!this.isAuthenticated) {
        this.setShowModalLoginRegister(true);
        return;
      }
      this.payload.SubTotal = this.subTotal;
      this.payload.product_name = this.payload.name;
      this.$gtag.event(this.$t("toast.txt_agrego_al_carrito"), {
        producto: this.payload.name,
      });

      let add = [];
      this.additionals.forEach((additional) => {
        for (let n = 0; n < additional.cantidad; n++) {
          add.push(additional.id);
        }
      });
      let product_size_id = this.sizeSelect.map((size) => {
        return size.id;
      });
      let product_sizes2_id = this.sizeSelect2.map((size2) => {
        return size2.id;
      });
      let product_sizes3_id = this.sizeSelect3.map((size3) => {
        return size3.id;
      });
      let product_sizes4_id = this.sizeSelect4.map((size4) => {
        return size4.id;
      });
      let response = null;
      if (this.selectedItemCarrito !== null) {
        response = await OrderServices.updateShoppingCartItem({
          id: this.selectedItemCarrito.id,
          product_size_ids: product_size_id,
          product_sizes2_ids: product_sizes2_id,
          product_sizes3_ids: product_sizes3_id,
          product_sizes4_ids: product_sizes4_id,
          ingredients_add: add,
          cant: this.payload.SelectCantidad,
        });
        if (response.data.success) {
          this.$emit("pay", response.data.data);
          this.$toast.success(this.$t("toast.txt_item_actualizado_con_exito"));
          return;
        } else {
          this.$toast.error(response.data.message);
          return;
        }
      }
      let type_delivery = "3";

      if (this.typeOrder !== undefined && this.typeOrder != null) {
        type_delivery = this.typeOrder.toString();
      }
      response = await OrderServices.createShoppingCartItem({
        product: this.payload.id,
        product_size_ids: product_size_id,
        product_sizes2_ids: product_sizes2_id,
        product_sizes3_ids: product_sizes3_id,
        product_sizes4_ids: product_sizes4_id,
        ingredients_add: add,
        cant: this.payload.SelectCantidad,
        type_delivery: type_delivery,
      });
      if (response.data.success) {
        this.$toast.success(
          this.$t("toast.txt_agregado_a_tu_carrito_de_compras")
        );
        this.pushShoppingCartProduct(response.data.data);
      } else {
        this.$toast.error(
          this.$t("toast.txt_hubo_error"),
          response.data.message
        );
        return;
      }
      this.proceedToPay();
    },
    modifyExtraSelect(inputId, listNumber) {
      //actualiza el valor total conforme se seleccionan nuevo elementos
      //Actualizamos los platos auxAdditionals
      switch (listNumber) {
        case 1:
          if (
            this.sizeSelect.length > this.payloadOriginal.quantity_product_size
          ) {
            this.$toast.error(
              this.$t("toast.txt_tiene_el_maximo_de_productos_escogidos", {
                title: this.payloadOriginal.sizes1_title,
              })
            );
            this.sizeSelect.pop();
            document.getElementById(inputId).checked = false;
            return;
          }
          this.sizes.forEach((item) => {
            const selected = this.sizeSelect.find((s) => s.id === item.id);
            if (selected) {
              item.cantidad = 1;
            } else {
              item.cantidad = 0;
            }
          });
          break;
        case 2:
          if (this.sizeSelect2.length > this.payloadOriginal.quantity_sizes_2) {
            this.$toast.error(
              this.$t("toast.txt_tiene_el_maximo_de_productos_escogidos", {
                title: this.payloadOriginal.sizes2_title,
              })
            );
            this.sizeSelect2.pop();
            document.getElementById(inputId).checked = false;
            return;
          }
          break;
        case 3:
          if (this.sizeSelect3.length > this.payloadOriginal.quantity_sizes_3) {
            this.$toast.error(
              this.$t("toast.txt_tiene_el_maximo_de_productos_escogidos", {
                title: this.payloadOriginal.sizes3_title,
              })
            );
            this.sizeSelect3.pop();
            document.getElementById(inputId).checked = false;
            return;
          }
          break;
        case 4:
          if (this.sizeSelect4.length > this.payloadOriginal.quantity_sizes_4) {
            this.$toast.error(
              this.$t("toast.txt_tiene_el_maximo_de_productos_escogidos", {
                title: this.payloadOriginal.sizes4_title,
              })
            );
            this.sizeSelect4.pop();
            document.getElementById(inputId).checked = false;
            return;
          }
          break;
      }

      if (this.payloadOriginal.has_discount) {
        this.subTotal = parseFloat(this.payloadOriginal.promo_price);
      } else {
        this.subTotal = parseFloat(this.payloadOriginal.price);
      }

      this.additionals.map((item) => {
        this.subTotal =
          parseFloat(this.subTotal) +
          parseFloat(item.price_additional) * item.cantidad;
      });
      //costos de sizes
      this.sizes.map((size) => {
        this.subTotal =
          parseFloat(this.subTotal) +
          parseFloat(size.price_additional1) * size.cantidad;
      });
      //costos de sizes2
      this.sizeSelect2.map((size2) => {
        this.subTotal =
          parseFloat(this.subTotal) + parseFloat(size2.price_additional2);
      });
      //costos de sizes3
      this.sizeSelect3.map((size3) => {
        this.subTotal =
          parseFloat(this.subTotal) + parseFloat(size3.price_additional3);
      });
      //costos de sizes4
      this.sizeSelect4.map((size4) => {
        this.subTotal =
          parseFloat(this.subTotal) + parseFloat(size4.price_additional4);
      });
    },
    showSubMenu() {
      this.first_list_collapsed = !this.first_list_collapsed;
    },
    showSubMenuDos() {
      this.second_list_collapsed = !this.second_list_collapsed;
    },
    showSubMenuTres() {
      this.third_list_collapsed = !this.third_list_collapsed;
    },
    showSubMenuCuatro() {
      this.fourth_list_collapsed = !this.fourth_list_collapsed;
    },
    showSubMenuCinco() {
      this.five_list_collapsed = !this.five_list_collapsed;
    },
  },
};
</script>

<style scoped>
.modal__bg {
  z-index: 15;
  padding: 0;
}

.fondo_modal_agregated {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: var(--text-white);
  overflow-y: auto;
  color: black;
}

.imagen__scroll {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  min-height: 240px;
  transition: 0.3s linear;
  transition-property: min-height, background-color;
  z-index: 5;
  padding: 10px;
  background-color: var(--secundary);
  background-position: center;
  background-repeat: no-repeat;
}

.class-with-img {
  background-size: cover;
}

.class-with-placeholder {
  background-size: contain;
}

.subtotal {
  display: none;
}

.product_description {
  display: none;
}

.product_description-mobile {
  opacity: 1;
  transition: opacity 0.6s linear;
}

.product_description-mobile-active {
  opacity: 0;
}

.imagen__scroll-active {
  min-height: 80px;
}

.imagen__scroll-active:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0.7;
  transition: background-color 0.3s linear;
}

.subtotal__scroll-active {
  position: relative;
  display: flex;
  gap: 10px;
  height: 100%;
  align-items: center;
  color: var(--text-white);
  z-index: 6;
}

.subtotal__scroll-active > *{
  color: var(--text-white);
}

.icon-close-scroll {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  background-color: var(--text-white);
  padding: 3px;
  width: 38px;
  height: 38px;
  cursor: pointer;
  border-radius: 50%;
}

.imagen__scroll img {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 150px;
  object-fit: cover;
}

.izquierda_modal {
  display: none;
}

.derecha_modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.wrapper__information {
  padding: 10px 16px;
}

.icon-close {
  display: none;
}

.titulo_producto {
  margin-bottom: 10px;
}

.product_title-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.precio_base {
  margin-left: auto;
  text-align: right;
}

.titles > .badge-base {
  opacity: 0;
  height: 0px;
  overflow: hidden;
  padding: 0;
  border-width: 0px;
  margin-left: auto;
  transition: all 0.3s linear;
}

.titles > .base_component-active {
  opacity: 1;
  height: max-content;
  overflow: visible;
  padding: 5px 10px;
  border-width: 1px;
}

.button-opcional {
  display: block;
  opacity: 0;
  height: 0px;
  overflow: hidden;
  padding: 0;
  border-width: 0px;
  margin-left: auto;
  transition: all 0.3s linear;
}

.button-opcional-active {
  margin-left: auto;
  width: max-content;
  height: max-content;
  opacity: 1;
  overflow: visible;
  padding: 5px 10px;
  border-width: 1px;
}

.titulos_sublistas {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.base_component-mobile {
  opacity: 1;
  margin-left: auto;
  margin-right: 20px;
  padding: 2px;
  border-radius: 50%;
  width: max-content;
  height: max-content;
  font-size: 1.125rem;
  transition: opacity 0.3s linear;
}

.base_component-mobile-active {
  opacity: 0;
}

.size_css-select {
  overflow: hidden;
  transition: max-height 0.6s ease-in-out;
}

.icon_less {
  transform: rotate(0deg);
  transition: transform 0.3s linear;
  cursor: pointer;
}

.icon_less-active {
  transform: rotate(-180deg);
}

.titles {
  margin-bottom: 10px;
}

.titles:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.sizes {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.size {
  padding-bottom: 5px;
  border-bottom: 1px solid var(--border-gray);
}

.additionals {
  margin-top: 10px;
}

.size_css-select,
.size_css-varios {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.size_card {
  position: relative;
}

.size_card input {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 3;
}

.label_item {
  cursor: pointer;
}

.size_card input:checked + .label_item {
  background-color: var(--secundary-100);
  color: var(--text-black);
}

.size_image_container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.size_image_container__img {
  width: 48px;
  height: 48px;
  border-radius: 4px;
}

.varios_img {
  display: block;
  width: 48px;
  height: 48px;
  overflow: hidden;
  border-radius: 4px;
}

.label_item,
.varios_sizes {
  position: relative;
  display: block;
  width: 100%;
  border-radius: 4px;
  padding: 5px;
  border: 1px solid var(--border-gray);
  transition: 0.3s ease all;
}

.varios_sizes {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

/*.opacity{
  opacity: 0;
}*/

.wrapper__precio-2 {
  display: none;
}

.wrapper__precio {
  position: sticky;
  bottom: 0;
  background-color: var(--text-white);
  z-index: 5;
  margin-top: auto;
  box-shadow: 0 -20px 20px -10px rgba(0, 0, 0, 0.1);
}

.message-price {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #FAB515;
  padding: 10px;
}

.message-price svg {
  min-width: 24px;
  min-height: 24px;
}

.message-price svg:last-child {
  cursor: pointer;
  margin-left: auto;
}

.precio_desktop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.precio_izquierda {
  display: none;
}

.precio_derecha {
  width: 100%;
}

.component_agregar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 5px 16px;
}

.varios__img {
  width: 48px;
  height: 48px;
  border-radius: 4px;
  overflow: hidden;
}

@media only screen and (min-width: 375px){
  .modal__bg {
    padding: 16px;
  }

  .fondo_modal_agregated {
    border-radius: 8px;
  }
}

@media only screen and (min-width: 768px) {
  .fondo_modal_agregated {
    max-width: 90%;
    height: 90dvh;
  }

  .imagen__scroll {
    display: none;
  }

  .icon-close {
    position: absolute;
    top: 40px;
    right: 10px;
    display: block;
    cursor: pointer;
    color: var(--text-white);
    cursor: pointer;
  }

  .product_description {
    display: block;
  }

  .product_description-mobile {
    display: none;
  }

  .titles {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .titles > .badge-base {
    opacity: 1;
    height: max-content;
    overflow: visible;
    padding: 5px 10px;
    border-width: 1px;
  }

  .titulos_sublistas .base_component-mobile {
    display: none;
  }

  .button-opcional {
    display: block;
    width: max-content;
    height: max-content;
    opacity: 1;
    overflow: visible;
    padding: 5px 10px;
    border-width: 1px;
  }

  .varios_sizes {
    gap: 20px;
    border: none;
    border-radius: 16px;
    overflow: hidden;
    padding: 0 10px 0 0;
  }

  .varios_img {
    width: 125px;
    height: 125px;
    border-radius: 16px;
    border: 1px solid var(--border-gray);
  }

  .precio_desktop {
    padding: 5px;
    bottom: -1px;
  }

  .precio_izquierda {
    display: block;
  }

  .precio_derecha {
    width: max-content;
  }

  .precio_izquierda-p-span {
    display: grid;
    grid-template-columns: 120px 1fr;
    gap: 4px 10px;
    align-items: center;
  }

  .precio_izquierda-p-span p {
    text-align: center;
  }

  .size_css-select {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px 10px;
  }

  .varios_sizes img {
    width: 125px;
    height: 125px;
  }

  .size_card {
    width: 125px;
    height: 100%;
    background-color: transparent;
    transition: all 0.3s linear;
    border-radius: 16px;
  }

  .size_card:hover {
    background-color: var(--secundary-50);
  }

  .size_card input {
    cursor: pointer;
  }

  .label_item {
    min-width: 120px;
    border: 1px solid var(--border-gray);
    border-radius: 16px;
    overflow: hidden;
    padding: 0;
  }

  .size_image_container {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }

  .size_image_container__img {
    width: 100%;
    height: 123px;
    border-radius: 0;
  }

  .size_image_container-paragraph {
    text-align: center;
    padding: 10px 5px;
  }

  .size_image_container-price {
    text-align: center;
    padding-bottom: 5px;
    font-size: 1rem;
  }
}

@media only screen and (min-width: 1024px) {
  .icon-close {
    position: fixed;
    width: 50px;
    height: 50px;
  }

  .product_arriba {
    display: flex;
    height: 100%;
  }

  .izquierda_modal {
    width: 40%;
    height: 100%;
    display: block;
  }

  .izquierda_modal img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .new_product_arriba {
    position: initial;
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 100%;
    overflow-y: auto;
  }

  .new_product_arriba-container {
    padding: 20px 16px;
  }

  .wrapper__precio {
    display: none;
  }

  .wrapper__precio-2 {
    position: sticky;
    bottom: -1px;
    display: block;
    box-shadow: 0 0 8px rgba(41, 41, 41, 0.2);
    background-color: var(--text-white);
    border-radius: 0;
    width: 100%;
    margin-top: auto;
    z-index: 10;
  }

  .precio_desktop-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    padding: 5px 10px;
  }

  .fondo_modal_agregated {
    overflow-y: initial;
  }

  .fondo_modal_agregated,
  .wrapper__information {
    padding: 0;
  }

  .wrapper__information {
    height: 100%;
    overflow: hidden;
  }
}

@media only screen and (min-width: 1600px){
  .fondo_modal_agregated {
    max-width: 1420px;
  }
}
</style>
