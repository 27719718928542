<template>
<!--    <WhatsappIconComponent-->
<!--        :phone=whatsapp_phone-->
<!--    ></WhatsappIconComponent>-->
  <CarouselQE
      :info_carousel="info_carousel"
      @banner_buy="$router.push('/menudigital/')"
  >
  </CarouselQE>
  <section class="carta">
    <div class="carta__container section-main">
      <h2 class="tp-head-responsive">{{ $t('home.txt_nuestra_carta') }}</h2>
      <section class="cartas">
        <CategoriasCarta
            v-for="(item, index) in listCartas"
            :category=item
            :key="index"
            @click="loadCard(item.id)"
        >
        </CategoriasCarta>
      </section>
      <ButtonSecundary
          type="button"
          @click="loadCard(null)"
      >
        {{ $t('buttons.txt_ordenar_ahora') }}
      </ButtonSecundary>
    </div>
    <img class="vector plant-1" src="../assets/images/vector-plant.webp" role="presentation" alt="">
    <img class="vector plant-2" src="../assets/images/vector-plant.webp" role="presentation" alt="">
  </section>
  <aside class="aside">
    <picture>
      <source
          type="image/webp"
          srcset="../assets/images/rebrandable/aside-bg-hero-desktop.webp"
          media="(min-width: 600px)"
          sizes="(max-width: 1920px) 100vw, 1920px"
      />
      <img
          loading="lazy"
          role="presentation"
          alt=""
          src="../assets/images/rebrandable/aside-bg-hero-desktop.webp"
          sizes="(max-width: 600px) 100vw, 600px"
      />
    </picture>
  </aside>
  <section class="about">
    <div class="about__container section-main">
      <div class="about__information">
        <img src="../assets/images/vector-semillas-cafe.webp" role="presentation" alt="">
        <h2 class="tp-head-responsive">Sobre manu café</h2>
        <p class="tp-body-responsive">Somos un espacio acogedor donde cada taza de café peruano de especialidad honra nuestra identidad. Ofrecemos a nuestros clientes una experiencia única y cercana, con un servicio cálido y auténtico que refleja con orgullo nuestras raíces peruanas.</p>
      </div>
      <img
          loading="lazy"
          :alt="'Equipo de ' + getBusinessName()"
          srcset="
                    ../assets/images/rebrandable/about.webp?width=200 200w,
                    ../assets/images/rebrandable/about.webp?width=400 400w,
                    ../assets/images/rebrandable/about.webp?width=600 600w
                "
          src="../assets/images/rebrandable/about.webp"
          sizes="(max-width: 500px) 100vw, 500px"
      />
    </div>
  </section>
  <GalleryComponent/>
  <section class="register">
    <div class="register__container section">
      <h3 class="register__subtitle">{{ $t('home.txt_quieres_estar_al_tanto_de') }} <br>{{ $t('home.txt_registrate') }}</h3>
      <ButtonPrimary
          type="button"
          @click="registerNowClicked"
      >
        {{ $t('buttons.txt_quiero_registrarme') }}
      </ButtonPrimary>
    </div>
  </section>
  <transition name="slide-fade">
    <LocalModalSelect
        v-if="showModalLocal"
        :ok="ModalLocal.ok"
        :no="ModalLocal.no"
        :message="ModalLocal.message"
        @ok="goToMenu"
        @no="showModalLocal=false"
    ></LocalModalSelect>
  </transition>
  <transition name="slide-fade">
    <ModalLocalSchedules
        v-if="showModalLocalSchedules"
        :ok="ModalLocal.ok"
        :no="ModalLocal.no"
        @ok="goToMenu"
        @no="showModalLocalSchedules=false"
    >
    </ModalLocalSchedules>
  </transition>
  <transition name="slide-fade">
    <ModalPhoneInput
        v-if="showModalPhoneInput"
        @closePhoneModal="showModalPhoneInput=false"
    >
    </ModalPhoneInput>
  </transition>
  <transition name="slide-fade">
    <LoadingBigComponent v-if="loading"/>
  </transition>
</template>

<script>

import LocalServices from "@/services/local-services"
import {mapMutations, mapState} from "vuex";
// import WhatsappIconComponent from "@/components/WhatsappIconComponent";
import CategoriasCarta from "@/components/Recursos/CategoriasCarta.vue";
import CarouselQE from "@/components/CarouselQE.vue";
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary.vue";
import LocalModalSelect from "@/components/MODALS/LocalModalSelect";
import ModalLocalSchedules from "@/components/MODALS/ModalLocalSchedules"
import ModalPhoneInput from "@/components/MODALS/ModalPhoneInput";
import LoadingBigComponent from "@/components/Recursos/LoadingBigComponent";
import ButtonSecundary from "@/components/new/buttons/ButtonSecundary.vue";
import {useRoute} from "vue-router";
import {useHead} from "@unhead/vue";
import GalleryComponent from "@/components/GalleryComponent";

export default {
  name: 'Home',
  components: {
    GalleryComponent,
    ButtonSecundary,
    LoadingBigComponent,
    ModalLocalSchedules,
    LocalModalSelect,
    ButtonPrimary,
    CarouselQE,
    CategoriasCarta,
    // WhatsappIconComponent,
    ModalPhoneInput,
},
  setup() {
    const route = useRoute();
    const canonicalUrl = `${process.env.VUE_APP_BASE_URL}${route.path}`;
    useHead({
      title: `Inicio`,
      link: [
        { rel: 'canonical', href: canonicalUrl }
      ],
      meta: [
        {
          name: "description",
          content:
              `Bienvenido a ${process.env.VUE_APP_BUSINESS_COMERCIAL_NAME}`,
        },
        { name: "author", content: "Quick eat!" },
        { name: "copyright", content: "Quick eat!" },

        { name: "robots", content: "index, follow" },
        { name: "googlebot", content: "index, follow" },
        { name: "language", content: "Spanish, English" },
        { name: "rating", content: "General" },
      ],
    });
  },
  async created () {
    this.is_ssr = typeof window === 'undefined';
  },
  async mounted() {
    this.getWhatsappPhone()
    await this.getLocals()
    await this.getCategories()
    if (!this.is_ssr) {
      window.scrollTo(0, 0)
    }
    this.loading = false
  },
  data () {
    return {
      listCartas: [],
      homeLocalList: [],
      info_carousel: [1, 2 ,3],
      showModalLocal: false,
      showModalLocalSchedules: false,
      loading: true,
      ModalLocal: {
        ok: '',
        no: '',
        message: ''
      },
      category_home: 0,
      whatsapp_phone: '',
      showModalPhoneInput: false,
      is_ssr: false,
    }
  },

  computed: {
    ...mapState(['isAuthenticated', 'user', 'listLocals', 'orderLocal', 'isSelfService'])
  },
  methods: {
    getBusinessName() {
      return process.env.VUE_APP_BUSINESS_COMERCIAL_NAME
    },
    ...mapMutations(['setUser', 'setAuthenticatedTrue', 'setShowModalLoginRegister', 'setOrderLocal']),
    registerNowClicked() {
      if (!this.isAuthenticated) {
        this.setShowModalLoginRegister(true)
        return
      }
      this.$toast.info(this.$t('home.txt_ya_se_encuentra_registrado'))
    },
    getWhatsappPhone() {
      this.whatsapp_phone = process.env.VUE_APP_WHATSAPP_PHONE
    },
    async getLocals() {

      let response = await LocalServices.listLocalesCercanos()
      if (response.data.success) {
        response.data.data = response.data.data.filter(item => {
          if (!item.deleted) {
            return item
          }
        })
        for (let i = 0; i < response.data.data.length; i++) {
          const dayLists = [[], [], [], [], [], [], []];

          for (let g = 0; g < response.data.data[i].schedules.length; g++) {
            const day = parseInt(response.data.data[i].schedules[g].day, 10);
            dayLists[day].push(response.data.data[i].schedules[g]);
          }
          for (let z=0; z < dayLists.length; z++) {
            if (dayLists[z].length > 0) {
              dayLists[z] = dayLists[z].sort(function (a, b) {
                if (a.time_init > b.time_init) {
                  return 1;
                }
                if (a.time_init < b.time_init) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });
            }
          }
          response.data.data[i].day_list = dayLists
        }
        this.homeLocalList = response.data.data
        if (this.orderLocal == null || !this.isSelfService) {
          this.setOrderLocal(this.homeLocalList.find(local => local.id === parseInt(process.env.VUE_APP_FIRST_LOCAL_ID)))
        }

        let matchingLocal = this.homeLocalList.find(local => local.id === this.orderLocal.id);

        if (matchingLocal) {
          this.setOrderLocal(matchingLocal);
        } else {
          if (this.homeLocalList.length > 0) {
            this.setOrderLocal(this.homeLocalList[0])
          } else {
            this.setOrderLocal(null)
          }
        }

      }

    },

    loadCard (category_id) {
      this.category_home = category_id
      if (!this.orderLocal.is_available_local) {
        this.ModalLocal.message = this.$t('home.txt_local_saturado_lo_sentimos');
        this.ModalLocal.ok = this.$t('dropdownaddress.txt_ver_carta');
        this.ModalLocal.no = this.$t('dropdownaddress.txt_regresar');
        this.showModalLocal = true
        return
      }
      if (!this.orderLocal.is_in_area) {
        this.ModalLocal.ok = this.$t('dropdownaddress.txt_ver_carta');
        this.ModalLocal.no = this.$t('dropdownaddress.txt_regresar');
        this.showModalLocalSchedules = true
        return
      }
      if (this.orderLocal.is_available_message) {
        this.ModalLocal.message = this.orderLocal.message
        this.ModalLocal.ok = this.$t('dropdownaddress.txt_si');
        this.ModalLocal.no = this.$t('dropdownaddress.txt_no');
        this.showModalLocal = true
        return
      }
      this.goToMenu()
    },

    goToMenu() {
      if (this.category_home !== null) {
        this.$router.push(`/menudigital/${this.category_home}`)
        return
      }
      this.$router.push({name: 'menudigital'})
    },
    async getCategories () {
      try {
        let response = await LocalServices.listCategoriesOfLocal(this.orderLocal.id)
        if (response.data.success) {
          let promo = response.data.data.filter(carta => {
            if (!carta.deleted && carta.is_promotion) {
              return carta
            }
          })
          promo.sort(function (a, b) {
            if (a.position > b.position) {
              return 1;
            }
            if (a.position < b.position) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });

          let normal = response.data.data.filter(carta => {
            if (!carta.deleted && !carta.is_promotion) {
              return carta
            }
          })
          normal.sort(function (a, b) {
            if (a.position > b.position) {
              return 1;
            }
            if (a.position < b.position) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });

          this.listCartas = promo.concat(normal)
        }
      } catch (error) {
        console.log(error)
      }
    },
    openLink (to) {
      switch (to) {
        case 'android':
          window.open('https://play.google.com/store/apps/details?id=cquickeat.elbarrilitoexpress', '_blank')
          break;
        case 'iOS':
          window.open('https://apps.apple.com/es/app/barrilito-express/id1524896987', '_blank')
          break
      }
    },
  }
}
</script>

<style scoped>
  /* ------------ INICIO DE SECCIÓN CARTA ------------ */

  .carta{
    position: relative;
    background-color: var(--primary);
    overflow: hidden;
  }

  .carta .vector{
    display: none;
    position: absolute;
  }

  .carta__container{
    position: relative;
    z-index: 5;
  }

  .carta__container > h2{
    text-align: center;
    margin-bottom: 30px;
    color: var(--text-white);
  }

  .carta button{
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    text-transform: uppercase;
  }

  .cartas{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
  }

  /* ------------ FIN DE SECCIÓN CARTA ------------ */

  /* ------------ INICIO DE SECCIÓN ABOUT ------------ */
  .about{
    background-color: var(--primary);
  }

  .about__container{
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .about__information{
    width: clamp(200px, 100%, 375px);
    margin-inline: auto;
  }

  .about__information > img{
    display: none;
  }

  .about__information :is(h2, p){
    text-align: center;
    color: var(--text-white);
  }

  .about__information h2{
    margin-bottom: 10px;
  }


  .about__container > img{
    display: block;
    width: clamp(200px, 100%, 350px);
    margin-inline: auto;
  }
  /* ------------ FIN DE SECCIÓN ABOUT ------------ */

  /* ------------ INICIO DE SECCIÓN APP ------------ */

  .app{
    background-color: #2A2A2A;
    color: var(--text-white);
  }

  .app__subtitle{ text-align: center; }

  .app__information-image{
    display: flex;
    gap: 20px;
  }

  .app__information{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    width: 70%;
  }

  .app__subtitle-2{ display: none; }

  .app__description-2{ display: none}

  .app__description{ font-size: 1rem; }

  .app__store{ display: none; }

  .app__store img{
    width: 50%;
    cursor: pointer;
  }

  .app__image{
    width: 30%;
    text-align: right;
  }

  /* ------------ FIN DE SECCIÓN APP ------------ */

  /* ------------ INICIO DE ASIDE APP ------------ */
  .aside img{
    width: 100%;
    height: auto;
    max-height: 70dvh;
    object-fit: cover;
  }

  /* ------------ FIN DE ASIDE APP ------------ */

  /* ------------ INICIO DE SECCIÓN REGISTER ------------ */

  .register{
    display: none;
    background-image: url("https://quickeat.s3.us-west-2.amazonaws.com/local/Man+Man+Chi/new_web/about-img-5.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 0px 0px 250px rgba(0, 0, 0, .6) inset;
    margin-bottom: 50px;
  }

  .register__container{
    text-align: center;
  }

  .register__subtitle{
    font-size: 1.125rem;
    line-height: 1.2;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    color: var(--text-white);
  }

  .register__container button{
    margin-left: auto;
    margin-right: auto;
  }

  /* ------------ FIN DE SECCIÓN REGISTER ------------ */

  @media screen and (min-width: 360px){
    .cartas{
      gap: 12px;
    }
  }

  @media only screen and (min-width: 600px){
    .cartas{
      gap: 16px;
    }

    .aside img{
      height: 100%;
      max-height: 100%;
      aspect-ratio: 16 / 7.5;
    }

    .about__container{
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
    }

    .about__container > img{
      width: clamp(200px, 100%, 300px);
      margin-inline: initial;
    }

    .about__information{
      margin-inline: initial;
    }
  }

  @media screen and (min-width: 905px){
    .cartas{
      gap: 16px;
    }

    .about__container > img{
      width: clamp(200px, 100%, 425px);
    }

    .about__information > img{
      display: block;
      margin-inline: auto;
      width: 125px;
    }

    .about__information h2{
      margin-top: 10px;
    }
  }

  @media screen and (min-width: 1240px){
    .cartas{
      gap: 24px;
    }

    .carta .vector{
      display: block;
    }

    .carta .plant-1{
      top: 0;
      left: -30%;
      z-index: 1;
    }

    .carta .plant-2{
      bottom: -100px;
      transform: rotate(300deg);
      right: -30%;
      z-index: 1;
    }

    .carta__subtitle{
      font-size: var(--fs-head-large);
      line-height: 42px;
    }

    .about__container{
      gap: 50px;
    }

    .about__container > img{
      width: clamp(200px, 100%, 500px);
    }

    .about__information h2{
      margin: 20px 0;
    }
  }
</style>